const Lang = {
    name: ['Name', 'Ime'],
    description: ['Description', 'Opis'], 
    shippingInfo: ['Shipping info', 'Informacije za pošiljke'],        
    language: ['Language', 'Jezik'],  
    adminLanguage: ['Admin language', 'Admin jezik'], 
    saveChanges: ['Save changes', 'Snimi promene'], 
    image: ['Image', 'Slika'], 
    price: ['Price', 'Cena'], 
    action: ['Action', 'Akcija'], 
    status: ['Status', 'Status'], 
    statusHeader: ['Shown in header', 'Prikazan u headeru'], 
    featuredImage: ['Featured image', 'Istaknuta slika'], 
    products: ['Products', 'Proizvodi'], 
    addProduct: ['Add product', 'Dodaj proizvod'], 
    search: ['Search...', 'Pretraga...'],
    page: ['Page', 'Strana'],
    slug: ['Slug', 'Slug'],
    title: ['Title', 'Naslov'],
    featured: ['Featured', 'Na početnoj strani'],
    helpFeatured: ['* the post is displayed on the home page', '* post se prikazuje na početnoj stranici'],
    helpPageCategories: ['* posts from selected categories will be displayed on this page', '* postovi iz odabranih kategorija će biti prikazani na ovoj stranici'],
    shortDescription: ['Short description', 'Kratak opis'],
    author: ['Author', 'Autor'],
    users: ['Users', 'Korisnici'],
    close: ['Close', 'Zatvori'],
    dimensions: ['Dimensions', 'Dimenzije'],
    height: ['Height', 'Visina'],
    width: ['Width', 'Širina'],
    length: ['Length', 'Dužina'],
    sizeUnit: ['Size unit', 'Jedinica mere za dužinu'],
    weight: ['Weight', 'Težina'],
    weightUnit: ['Weight unit', 'Jedinica mere za težinu'],
    quantity: ['Quantity', 'Količina'],
    sale: ['Sale', 'Prodaja'],
    discount: ['Discount', 'Popust'],
    saveProduct: ['Save product', 'Sačuvaj proizvod'],
    active: ['Active', 'Aktivan'],
    shownAtHome: ['Shown at home page', 'Prikazan na početnoj'],
    productImage: ['Product image', 'Slika proizvoda'],
    logo: ['Logo', 'Logo'],
    changeImage: ['Change image', 'Zameni sliku'],
    additionalImages: ['Additional images', 'Dodatne slike'],
    addImage: ['Add image', 'Dodaj sliku'],
    categories: ['Categories', 'Kategorije'],
    selectProductImage: ['Select product image', 'Izaberi sliku proizvoda'],
    selectFeaturedImage: ['Select featured image', 'Izaberi glavnu sliku'],
    upload: ['Upload', 'Dodaj'],
    addCategory: ['Add category', 'Dodaj kategoriju'],
    addPage: ['Add page', 'Dodaj stranicu'],
    uploadMedia: ['Upload media', 'Dodaj sliku'],
    edit: ['Edit', 'Izmeni'],
    deleteCategory: ['Delete category', 'Izbriši kategoriju'],
    deletePage: ['Delete page', 'Izbriši stranicu'],
    submit: ['Submit', 'Potvrdi'],
    putUnder: ['Put under', 'Stavi pod'],
    addBanner: ['Add banner', 'Dodaj baner'],
    selectBannerImage: ['Select banner image', 'Izaberi sliku banera'],
    delete: ['Delete', 'Izbriši'],
    moveDown: ['Move down', 'Pomeri dole'],
    moveUp: ['Move up', 'Pomeri gore'],
    store: ['Store', 'Prodavnica'],
    products: ['Products', 'Proizvodi'],
    media: ['Media', 'Media'],
    categories: ['Categories', 'Kategorije'],
    banners: ['Banners', 'Baneri'],
    pages: ['Pages', 'Stranice'],
    orders: ['Orders', 'Porudžbine'],
    socialNetworks: ['Social networks', 'Društvene mreže'],
    themes: ['Themes', 'Teme'],
    apply: ['Apply', 'Primeni'],
    preview: ['Preview', 'Pogledaj'],
    order: ['Order', 'Porudžbina'],
    details: ['Details', 'Detalji'],
    municipality: ['Municipality', 'Opština'],
    customer: ['Customer', 'Kupac'],
    shippingAddress: ['Shipping address', 'Adresa isporuke'],
    total: ['Total', 'Total'],
    show: ['Show', 'Prikaži'],
    smallDevice: ['Small device', 'Mali uređaj'],
    shippingPriceSerbia: ['Shipping price for Serbia', 'Cena poštarine za Srbiju'],
    shippingPriceAfrica: ['Shipping price for Africa', 'Cena poštarine za Afriku'],
    shippingPriceAsia: ['Shipping price for Asia', 'Cena poštarine za Aziju'],
    shippingPriceAntarctica: ['Shipping price for Antarctica', 'Cena poštarine za Antarktik'],
    shippingPriceEurope: ['Shipping price for Europe', 'Cena poštarine za Evropu'],
    shippingPriceOceania: ['Shipping price for Oceania', 'Cena poštarine za Okeaniju'],
    shippingPriceNorthAmerica: ['Shipping price for North America', 'Cena poštarine za Severnu Ameriku'],
    shippingPriceSouthAmerica: ['Shipping price for South America', 'Cena poštarine za Južnu Ameriku'],
    shippingFreeLimit: ['Shipping free limit', 'Besplatna isporuka preko'],
    shippingPrices: ['Shipping prices', 'Cene isporuke'],
    shipping: ['Shipping', 'Pošiljke'],
    successMessageSetBanner: ['You have successfully set banner.', 'Uspešno ste ubacili baner.'],
    successMessageCreateCategory: ['You have successfully create category.', 'Uspešno ste kreirali kategoriju.'],
    successMessageCommonContent: ['You have successfully update data.', 'Uspešno ste uneli podatke.'],
    successMessageCreatePage: ['You have successfully create page.', 'Uspešno ste kreirali stranicu.'],
    successMessageCreatePost: ['You have successfully create post.', 'Uspešno ste kreirali vest.'],
    successMessageUpdateCategory: ['You have successfully update category.', 'Uspešno ste izmenili kategoriju.'],
    successMessageParentCategory: ['You have successfully set category.', 'Uspešno ste izabrali kategoriju.'],
    successMessageUpdatePage: ['You have successfully update page.', 'Uspešno ste izmenili stranicu.'],
    successMessageUpdatePost: ['You have successfully update post.', 'Uspešno ste izmenili vest.'],
    successMessageCreateProduct: ['You have successfully create product.', 'Uspešno ste kreirali proizvod.'],
    successMessageUpdateProduct: ['You have successfully update product.', 'Uspešno ste izmenili proizvod.'],
    successMessageSetProductImage: ['You have successfully update image.', 'Uspešno ste izmenili sliku.'],
    successMessageSetProductGallery: ['You have successfully upload image.', 'Uspešno ste dodali sliku.'],
    successMessageUpdateStore: ['You have successfully update store informations.', 'Uspešno ste uneli podatke o prodavnici.'],
    successMessageAddToCart: ['Product successfully added to cart.', 'Proizvod je dodat u korpu.'],
    successMessageSetLogoImage: ['You have successfully update logo.', 'Uspešno ste izmenili logo.'],
    successMessageApplyTheme: ['You have successfully set new theme.', 'Uspešno ste izmenili temu.'],
    successMessageSetPageFeaturedImage: ['You have successfully update featured image.', 'Uspešno ste glavnu sliku.'],
    successMessageDeleteOrder: ['You have successfully delete order.', 'Uspešno ste obrisali porudžbinu.'],
    successMessageSocialNetworks: ['You have successfully update social networks.', 'Uspešno ste izmenili društvene mreže.'],
    successMessageSetHtml: ['You have successfully set HTML.', 'Uspešno ste izmenili HTML.'],
    cart: ['Cart:', 'Korpa:'],
    adminLogin: ['Admin Login', 'Admin Logovanje'],
    adminLoginEnter: ['Please enter your email and password to access the admin section:', 'Unesite vaš email i lozinku kako biste pristupili admin panelu:'],
    email: ['email', 'email'],
    password: ['password', 'lozinka'],
    cartCart: ['Cart', 'Korpa'],
    cartProduct: ['PRODUCT', 'PROIZVOD'],
    cartPrice: ['PRICE', 'CENA'],
    cartQuantity: ['QUANTITY', 'CENA'],
    cartTotal: ['TOTAL', 'UKUPNO'],
    cartSubtotal: ['Subtotal', 'Ukupno'],
    cartShipping: ['Shipping', 'Isporuka'],
    cartEmptyMessage: ['Your cart is empty', 'Vaša korpa je prazna'],
    cartName: ['Name and surname', 'Ime i prezime'],
    cartAddress: ['Billing address', 'Adresa i broj'],
    cartMunicipality: ['Municipality', 'Opština'],
    cartZipCode: ['Zip Code', 'Poštanski broj'],
    cartCity: ['City', 'Grad'],
    cartCountry: ['Country', 'Država'],
    cartPhone: ['Phone number', 'Broj telefona'],
    cartEmail: ['Email', 'E-mail'],
    cartNote: ['Note', 'Poruka'],
    cartShippingName: ['Shipping name and surname', 'Ime i prezime isporuke'],
    cartShippingAddress: ['Shipping address', 'Adresa i broj isporuke'],
    cartShippingPhone: ['Shipping phone', 'Broj telefona isporuke'],
    cartShippingEmail: ['Shipping email', 'E-mail isporuke'],
    cartShippingAddressMessage: ['<b>Shipping address</b> is different from the billing address? Click here and fill in the information related to the shipping address', '<b>Adresa isporuke</b> je drugačija od adrese plaćanja? Kliknite ovde i popunite podatke vezano za adresu isporuke'],
    cartButton: ['Order', 'Naruči'],
    headerShop: ['Shop', 'Katalog'],
    headerTermsOfUse: ['Terms of use', 'Uslovi korišćenja'],
    headerContact: ['Contact', 'Kontakt'],
    headerCart: ['Cart', 'Korpa'],
    shopShop: ['Shop', 'Katalog'],
    contactUsButton: ['contact us', 'kontaktirajte nas'],
    soldOutButton: ['sold out', 'rasprodato'],
    addToCartButton: ['add to cart', 'dodaj u korpu'],
    contactContact: ['Contact', 'Kontakt'],
    contactAddress: ['Address', 'Adresa'],
    contactPhone: ['Phone', 'Telefon'],
    contactPhone2: ['Phone 2', 'Telefon 2'],
    contactEmail: ['Email', 'E-mail'],
    footerInfo1: ['For notifications about the latest products, sign up for the <a href="/terms-of-use">mailing list</a>.', 'Za obaveštenja o najnovijim proizvodima se prijavite na <a href="/terms-of-use">mailing listu</a>.'],
    termsOfUseFooter: ['<a href="/terms-of-use">Terms of use</a>', '<a href="/terms-of-use">Uslovi korišćenja</a>'],
    privacyPolicyFooter: ['<a href="/privacy-policy">Privacy policy</a>', '<a href="/privacy-policy">Politika privatnosti</a>'],
    noMatchTitle: ['Page not found', 'Stranica nije pronađena'],
    noMatchSubTitle: ['Click <a href="/shop">here</a> to view our products.', 'Kliknite <a href="/shop">ovde</a> da pogledate nase proizvode.'],
    signInBtn: ['Login', 'Ulogujte se'],
    signUpBtn: ['Sign up', 'Registruj se'],
    logoutBtn: ['Logout', 'Izloguj se'],
    signinTitle: ['Sign in', 'Prijava'],
    signupTitle: ['Sign up', 'Registracija'],
    registerEmail: ['email:', 'email:'],
    registerName: ['full name:', 'ime i prezime:'],
    emailErrorMessage: ['* please enter valid email.', '* morate da unesete validnu email adresu.'],
    registerPassword: ['password:', 'šifra:'],
    confirmPassword: ['confirm password:', 'ponovi šifru:'],
    registerRepeatPassword: ['repeat password:', 'ponovi šifru:'],
    registerClickHereBtn: ['click here', 'kliknite ovde'],
    registerForgotPassword: ['Forgot password?', 'Ako ste zaboravili šifru'],
    registerEmailErrorMessage: ['* please enter valid email.', '* morate da unesete validnu email adresu.'],
    registerPasswordErrorMessage: ['* please enter your password.', '* morate da unesete šifru.'],
    registerNameErrorMessage: ['* please enter your name and surname.', '* morate da unesete ime i prezime.'],
    registerRepeatPasswordErrorMessage: ['* passwords are not identical!', '* šifre nisu identicne!'],
    registerResetPasswordAlert: ['Enter your email to reset the password.', 'Unesite vaš email kako biste resetovali šifru.'],
    checkRegisterInputs: ['You did not enter all the data.', 'Niste uneli sve podatke.'],
    registerEmailExistError: ['Error. Email already exist.','Greška. Ovaj mejl je vec iskorišćen.'],
    registerRegistrationError: ['Registration failed, please try again.','Registracija nije uspela, pokušajte ponovo.'],
    cookiesContent: ['The site uses cookies that do not contain personal information and serve to improve the user experience of our site. Presence on the site implies that visitors agree to the use of these cookies. For more details on data protection, read in', 'Sajt koristi kolačiće (cookies) koji ne sadrže lične podatke i služe za poboljšanje korisničkog iskustva naše stranice. Prisutnost na sajtu, podrazumeva da se posetioci slažu sa korišćenjem ovih kolačića. Za više detalja o zaštiti podataka pročitajte u'],
    privacyPolicy: ['Privacy policy', 'Polisa privatnosti'],
    acceptButton: ['Accept', 'Prihvatam'],
    registerInfoMsg: ['If you want to sign up, please confirm your password', 'Ukoliko želite da se registrujete, potvrdite šifru'],
    contactInfo: ['Contact info', 'Kontakt podaci'],
    // Errors
    no_name: ['* please enter your name and surname.', '* morate da unesete vaše ime i prezime.'],
    no_billing_address: ['* please enter your billing address.', '* morate da unesete vašu adresu.'],
    no_city: ['* please enter your city.', '* morate da unesete vaš grad.'],
    no_municipality: ['* please enter your municipality.', '* morate da unesete vašu opštinu.'],
    no_phone: ['* please enter your phone number.', '* morate da unesete vaš broj telefona.'],
    no_email: ['* please enter your email.', '* morate da unesete vašu email adresu.'],
    no_zip_code: ['* please enter your zip code.', '* morate da unesete poštanski broj.'],
    no_country: ['* please enter your country.', '* morate da unesete vašu državu.'],
    no_shipping_name: ['* please enter shipping name and surname.', '* morate da unesete ime i prezime isporuke.'],
    no_shipping_address: ['* please enter shipping address.', '* morate da unesete adresu isporuke.'],
    no_shipping_city: ['* please enter shipping city.', '* morate da unesete grad isporuke.'],
    no_shipping_municipality: ['* please enter shipping municipality.', '* morate da unesete opštinu isporuke.'],
    no_shipping_phone: ['* please enter shipping phone.', '* morate da unesete broj telefona isporuke.'],
    no_shipping_zip_code: ['* please enter shipping zip code.', '* morate da unesete poštanski broj isporuke.'],
    no_shipping_country: ['* please enter shipping country.', '* morate da unesete državu isporuke.'],
    no_shipping_email: ['* please enter shipping email.', '* morate da unesete email adresu isporuke.'],
    no_input: ['* You have not entered all the required information.', '* Niste uneli sve potrebne podatke.'],
    no_products: ['* Your cart is empty, please add a product to the cart.', '* Vaša korpa je prazna, molimo vas da ubacite neki proizvod u korpu.'],
    success_message: ['You have successfully completed your purchase', 'Uspešno ste obavili kupovinu'],
    fail_message: ['Your purchase could not be completed, please try again', 'Neuspešno plaćanje, molimo pokusajte ponovo'],
    success_transaction_id: ['Transaction ID:', 'ID Transakcije:'],
    success_order_number: ['Order number:', 'Broj porudžbine:'],
    success_address: ['Address', 'Adresa'],
    success_articles: ['Products', 'Artikli'],
    success_pcs: ['PCS', 'KOM'],
    serbianVersion: ['Serbian version', 'Srpska verzija'],
    englishVersion: ['English version', 'Engleska verzija'],
    payment_cart: ['Payment cart', 'Platnom karticom'],
    on_delivery: ['On delivery', 'Pouzećem'],
    perPage: ['Per page', 'Po stranici']
}

export default Lang;